import React from 'react'

function HeaderArrowRightSvg() {
    return (
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.473441 9.2443C0.402611 9.17362 0.346417 9.08966 0.308076 8.99723C0.269735 8.9048 0.25 8.80572 0.25 8.70565C0.25 8.60558 0.269735 8.5065 0.308076 8.41407C0.346417 8.32164 0.402611 8.23768 0.473441 8.167L3.43794 5.2025L0.473441 2.238C0.330581 2.09515 0.250324 1.90139 0.250324 1.69935C0.250324 1.49732 0.330581 1.30356 0.473441 1.1607C0.6163 1.01784 0.810059 0.937584 1.01209 0.937584C1.21413 0.937584 1.40789 1.01784 1.55074 1.1607L5.05771 4.66767C5.12854 4.73835 5.18474 4.82231 5.22308 4.91474C5.26142 5.00717 5.28115 5.10626 5.28115 5.20632C5.28115 5.30639 5.26142 5.40547 5.22308 5.4979C5.18474 5.59033 5.12854 5.67429 5.05771 5.74497L1.55074 9.25194C1.26041 9.54228 0.771419 9.54228 0.473441 9.2443Z" fill="#383838" />
        </svg>
    )
}

export default HeaderArrowRightSvg