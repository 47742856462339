export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_API = process.env.REACT_APP_API_URL
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const GOOGLE_LOCATION_KEY = process.env.REACT_GOOGLE_LOCATION_KEY
export const BASE_STORAGE_URL = process.env.REACT_APP_STORAGE_URL
// export const CLIENT_ID = process.env.client_id
// export const CLIENT_SECRET = process.env.client_secret
// export const GRANT_TYPE = process.env.grant_type
// export const FACEBOOK_APP_ID = process.env.facebook_app_id
// export const GOOGLE_CLIENT_ID = process.env.google_client_id
// export const STRIPE_PK = process.env.stripe_pk
// export const FLEXE_PERCENTAGE = process.env.flexe_percentage
// export const WEBSOCKETS_KEY = process.env.web_sockets_key
// export const WEBSOCKETS_SECRET = process.env.web_sockets_secret
// export const WEBSOCKETS_HOST = process.env.web_sockets_host
// export const WEBSOCKETS_AUTH_ENDPOINT = process.env.web_sockets_auth_endpoint
// export const FEDEX_TEST_SENT_TRACKING = process.env.fedex_test_sent_tracking
// export const FEDEX_TEST_SHIPPING_STATUS = process.env.fedex_test_shipping_status

/* ===========================
        EVENTS
============================================== */
export const EVENT_LOGIN_MODAL = 'EVENT_LOGIN_MODAL'
export const EVENT_SIGNUP_MODAL = 'EVENT_SIGNUP_MODAL'

export const PRODUCT_ID = 1
export const CATEGORY_ID = 2

export const ATTRIBUTE_TYPES = {
  TEXT: 'TEXT',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  RADIO_GROUP: 'RADIO_GROUP',
  SELECT: 'SELECT',
}

export const OFFERS_STATUS = {
  new: { name: 'NEW_REQUEST' },
}
