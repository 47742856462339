import React from 'react'
import Checkimg from '../../assets/Images/Auction/check.png'
const Confirmation = () => {
  return (
    
        <div className='cnfm'>
            <img src={Checkimg} />
            <h2>Your Bid has been<br /> Successfully submitted</h2>
        </div>
 
  )
}

export default Confirmation